export const ROUTES = {
    CREATE_BUSINESS: "create-business",
    QR_RESOLVE: "qrresolve",
    BOOKING_DETAILS: "booking-details",
    ADD_VEHICLE: "add-vehicle",
    MODEL_LIST: "model-list",
    MIGRATION: "migration",
    STORE_DETAILS: "storeDetails",
    STORE_LIST: "storeList",
    STORE_SEARCH: "storeSearch",
    OPERATOR: "operator",
    ADD_STORE: "addstore",
    PLACES: "places",
    OUTLET: "outlet",
    IMAGE: "image",
    HEALTH_CHECK: "healthcheck",
    CHARGING_STATION: "charging-station",
    ONDEMAND_CHARGING: "ondemand",
    START_CHARGING: "start-charging",
    CHARGING_DETAILS: "charging-details",
  };
  