import React, { useEffect, useState } from "react";
import { FaSquarePhone } from "react-icons/fa6";
import { getActiveBooking, getAllBookings } from "../../api";
import { useNavigate } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";

const BookingPage = () => {
  const [activeTab, setActiveTab] = useState("live");
  const [liveBookings, setLiveBookings] = useState([]);
  const [allBookings, setAllBookings] = useState([]);
  const [activeBooking, setActiveBooking] = useState(null);
  const [bookingAddresses, setBookingAddresses] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        const fetchUserLocation = () => {
          return new Promise((resolve, reject) => {
            if (navigator.geolocation) {
              navigator.geolocation.getCurrentPosition(
                (position) => {
                  resolve({
                    latitude: position?.coords?.latitude,
                    longitude: position?.coords?.longitude,
                  });
                },
                (error) => {
                  reject(error);
                }
              );
            } else {
              reject(new Error("Geolocation not supported"));
            }
          });
        };

        const userLocation = await fetchUserLocation();
        const locationPayload = {
          location: {
            latLng: {
              latitude: userLocation?.latitude,
              longitude: userLocation?.longitude,
            },
            radiusInMeters: 5000,
          },
        };

        const liveResponse = await getActiveBooking(locationPayload);
        const allResponse = await getAllBookings(locationPayload);

        setLiveBookings(liveResponse?.bookings || []);
        setAllBookings(allResponse?.bookings || []);
      } catch (error) {
        console.error("Error fetching bookings:", error);
      }
    };

    fetchBookings();
  }, []);

  const fetchAddress = async (latitude, longitude, bookingId) => {
    try {
      const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`
      );
      const data = await response.json();
      const address =
        data?.results && data?.results?.length > 0
          ? data?.results[0]?.formatted_address
          : "Address not found";

      setBookingAddresses((prev) => ({ ...prev, [bookingId]: address }));
    } catch (error) {
      console.error("Error fetching address:", error);
    }
  };

  const handleBookingClick = (booking) => {
    setActiveBooking(booking);

    if (!bookingAddresses[booking.bookingId]) {
      const { latitude, longitude } = booking.userLocation?.latLng || {};
      if (latitude && longitude) {
        fetchAddress(latitude, longitude, booking.bookingId);
      }
    }
  };

  const handleClick = () => {
    navigate("/start-charging", { state: { bookingId: activeBooking?.bookingId } });
  };

  const formatTimestampToReadableDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString();
  };

  return (
    <div className="container mx-auto">
      {!activeBooking && (
        <div className="flex justify-center mb-6">
          <button
            className={`px-4 py-3 text-lg font-medium ${
              activeTab === "live"
                ? "text-green-600 border-b-4 border-green-600"
                : "text-black"
            }`}
            onClick={() => setActiveTab("live")}
          >
            Live Bookings
          </button>
          <button
            className={`px-4 py-3 text-lg font-medium ${
              activeTab === "all"
                ? "text-green-600 border-b-4 border-green-600"
                : "text-black"
            }`}
            onClick={() => setActiveTab("all")}
          >
            All Bookings
          </button>
        </div>
      )}

      <div>
        {activeBooking ? (
          <div>
            <button
              onClick={() => setActiveBooking(null)}
              className="text-blue-600 underline mb-4 flex items-center"
            >
              <AiOutlineArrowLeft className="text-xl" />
            </button>

            <div className="mb-4 bg-green-50 p-4 rounded-full">
              <div className="flex justify-between items-center">
                <span className="text-gray-600 font-bold text-lg">Target Range</span>
                <span className="text-green-600 font-bold text-xl">
                  {activeBooking?.socUpdateInfo?.targetRangeKms}km
                </span>
              </div>
            </div>
            <p className="mb-4 text-black-700 text-lg pl-6">
              Booking Id - {" "}
              <span className="font-semibold text-gray-700">
                {activeBooking?.bookingId}
              </span>
            </p>
            <p className="mb-4 text-black-700 text-lg pl-6">
              Starting charging level: {" "}
              <span className="font-semibold text-gray-700">
                {activeBooking?.socUpdateInfo?.startPercentage}%
              </span>
            </p>
            <p className="mb-4 text-black-700 text-lg pl-6">
              Starting range km: {" "}
              <span className="font-semibold text-gray-700">
                {activeBooking?.socUpdateInfo?.startRangeKms}km
              </span>
            </p>
            <p className="mb-4 text-black-700 text-lg pl-6">
              Kms to be added: {" "}
              <span className="font-semibold text-gray-700">
                {activeBooking?.socUpdateInfo?.targetRangeKms -
                  activeBooking?.socUpdateInfo?.startRangeKms}
                km
              </span>
            </p>

            <div className="bg-gray-100 p-4 rounded-lg pl-6">
              <p className="mb-2 text-gray-700 text-lg">
                Vehicle Name: {" "}
                <span className="text-xl font-bold text-black-500">
                  {activeBooking?.vehicleDetails?.modelDetails?.name}
                </span>
              </p>
              <p className="mb-2 text-gray-700 text-lg">
                Compatible Connectors: {" "}
                <span className="text-xl font-bold text-black-500">
                  {activeBooking?.connectorMeta?.type}
                </span>
              </p>
              <div className="mb-4 text-gray-700">
                <p className="text-sm">Customer Name:</p>
                <div className="flex items-center space-x-2">
                  <p className="text-xl font-bold text-black-500">
                    {activeBooking?.userDetails?.fullName}
                  </p>
                  <a href={`tel:${activeBooking?.userDetails?.phoneNumber}`}>
                    <span className="text-xl text-black">
                      <FaSquarePhone />
                    </span>
                  </a>
                </div>
              </div>
            </div>

            <div className="mb-6 pl-4 pr-6 pt-4">
              <p className="text-black text-2xl font-bold">Destination</p>
              <p className="text-gray-700">
                {bookingAddresses[activeBooking.bookingId] || "Fetching address..."}
              </p>
            </div>

            <div className="flex space-x-4 pl-4">
  <button
    onClick={handleClick}
    className="bg-green-500 text-white py-3 px-8 rounded-lg hover:bg-green-600 transition duration-300 w-full sm:w-auto flex items-center justify-center"
  >
    Start Charging
  </button>
  <a
    href={
      activeBooking?.userLocationNavigationInfo?.googleMapsNavigationLink
    }
    className="bg-blue-500 text-white border border-blue-500 py-2 px-4 rounded-full hover:bg-blue-600 transition duration-300 whitespace-nowrap flex items-center justify-center"
  >
    {activeBooking?.userLocationNavigationInfo?.navigationDistanceBetweenPointsInfo?.distance}{" "}
    km ➔
  </a>
</div>

          </div>
        ) : (
          (activeTab === "live" ? liveBookings : allBookings).map((booking, index) => (
            <div
              key={index}
              className="flex justify-between items-start border rounded p-4 mb-2 shadow cursor-pointer"
              onClick={() => handleBookingClick(booking)}
            >
              <div className="flex flex-col">
                <h2 className="font-bold">{booking.bookingId}</h2>
                <p className="text-sm text-gray-500">
                  Booking Time: {formatTimestampToReadableDate(booking.updatedAt)}
                </p>
                <p className="text-sm text-gray-500">Status: {booking.bookingStatus}</p>
              </div>
              <div className="flex flex-col items-end">
                <p className="font-bold text-black-600">
                  {booking.socUpdateInfo?.targetRangeKms}km
                </p>
                <p className="text-green-600 font-bold text-sm mt-3">
                  ₹
                  {(
                    booking.bookingForwardPaymentDetails?.paymentCharges?.totalAmount / 100
                  ).toFixed(2)}
                </p>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default BookingPage;
