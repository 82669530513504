import React, { useState } from "react";
import { useNavigate , useLocation } from "react-router-dom";
import { StartCharging } from "../../api"; 

const StartChargingComponent = () => {
    const location = useLocation();
  const { bookingId } = location.state || {};
    
    // console.log("Booking ID:", bookingId);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    chargingLevel: "",
    kmRange: "",
  });
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleOtpChange = (e, index) => {
    const value = e.target.value;
    if (/^\d$/.test(value) || value === "") {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      
      if (value && index < 3) {
        document.getElementById(`otp-box-${index + 1}`).focus();
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);

    const startOtp = otp.join(""); 
    const { chargingLevel, kmRange } = formData;

    if (!startOtp || !chargingLevel || !kmRange) {
      setError("All fields are required.");
      setLoading(false);
      return;
    }

    const payload = {
      startOtp: parseInt(startOtp, 10),
      startPercentage: parseFloat(chargingLevel),
      startRangeKms: parseFloat(kmRange),
    };

    try {
        // console.log("Payload",payload)
      const response = await StartCharging(bookingId, payload);
      // console.log("Payload",payload)
      // console.log("REPOSNE OF START Charging",response)
      if (response) {
        navigate("/charging-details", { state: { bookingId: bookingId } });
      } else {
        setError("Failed to start charging. Please try again.");
      }
    } catch (error) {
      setError(error.message || "An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-3xl font-bold mb-12">Start Charging</h1>
      <form className="space-y-6" onSubmit={handleSubmit}>
       
        {error && <p className="text-red-500">{error}</p>}

   
        <div className="flex items-center space-x-4 mt-6">
          <label className="block text-lg mb-2">Enter Start OTP</label>
          <div className="flex space-x-2">
            {otp.map((digit, index) => (
              <input
                key={index}
                id={`otp-box-${index}`}
                type="text"
                value={digit}
                onChange={(e) => handleOtpChange(e, index)}
                className="w-12 h-12 text-2xl text-center border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
                maxLength={1}
              />
            ))}
          </div>
        </div>

        
        <div>
          <label className="block text-lg mb-4">Enter Starting Charging Level</label>
          <input
            type="text"
            name="chargingLevel"
            value={formData.chargingLevel}
            onChange={handleChange}
            className="w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400 transition duration-300"
            placeholder="Enter %"
          />
        </div>

       
        <div>
          <label className="block text-lg mb-4">Enter Starting km Range</label>
          <input
            type="text"
            name="kmRange"
            value={formData.kmRange}
            onChange={handleChange}
            className="w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400 transition duration-300"
            placeholder="Enter km"
          />
        </div>

      
        <div className="flex space-x-4">
          <button
            type="submit"
            className={`py-3 px-8 rounded-lg transition duration-300 w-full sm:w-auto ${
              loading
                ? "bg-gray-500 text-white cursor-not-allowed"
                : "bg-green-500 text-white hover:bg-green-600"
            }`}
            disabled={loading}
          >
            {loading ? "Starting..." : "Start Charging"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default StartChargingComponent;
