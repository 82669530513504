import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  SIDEBAR_TILES,
  SIDEBAR_BREAKPOINT,
} from "../constants/sidebarConstants";
import { getAuth } from "../utils/common";
import { BsChevronDown } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { AuthService } from "../AuthService";

export const LOGOUT_KEY = "Logout";

const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [openTab, setOpenTab] = useState(null); // For submenu toggle
  const [ , setUserType] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const toggleTab = (tab) => {
    setOpenTab(openTab === tab ? null : tab); // Toggle submenu open/close
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  // Determine active class for a menu item
  const getActiveClass = (path) => {
    return location.pathname === path ? "bg-gray-700 border-l-4 border-blue-500" : "";
  };

  const handleLinkClick = () => {
    if (isMobile) {
      setIsSidebarOpen(false);
    }
  };

  const handleLogout = () => {
    AuthService.clearToken(); // Clear the token or authentication data
    // console.log("Token cleared, redirecting to /auth...");

    // Navigate to the auth page
    navigate("/auth", { replace: true });
  };

  useEffect(() => {
    const auth = getAuth();
    const user = JSON.parse(auth);
    setUserType(user?.roles[0]);

    const handleResize = () => {
      setIsMobile(window.innerWidth < SIDEBAR_BREAKPOINT);
      if (window.innerWidth >= SIDEBAR_BREAKPOINT) setIsSidebarOpen(true);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="max-h-screen overflow-hidden fixed bg-gray-800 z-[9999]">
      {isMobile && !isSidebarOpen && (
        <button
          className="lg:hidden p-3 fixed top-0 left-0 z-50"
          onClick={toggleSidebar}
        >
          <span className="bg-gray-800 text-white p-2 rounded">☰</span>
        </button>
      )}

      <div
        className={`fixed top-0 left-0 h-screen w-64 bg-gray-800 text-white z-50 transform ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-300 ease-in-out lg:translate-x-0 lg:relative lg:w-64 max-h-screen overflow-y-auto`}
        style={{ boxShadow: isMobile && isSidebarOpen ? "0 0 10px rgba(0, 0, 0, 0.8)" : "" }}
      >
        <div className="inline-flex">
          <h1 className="text-white origin-left font-medium text-2xl p-5">
            Internal App
          </h1>
        </div>

        <ul className="pt-2">
          {SIDEBAR_TILES.map((menu, index) => (
            <React.Fragment key={index}>
              <li
                className={`text-white-300 text-sm flex items-center justify-between gap-x-4 cursor-pointer p-2 hover:bg-gray-700 hover:text-white rounded-md mt-3 ml-3 mr-3 ${menu.spacing ? "mt-9" : "mt-2"
                  } ${getActiveClass(menu.path)}`}
              >
                {menu.title === LOGOUT_KEY  ? (
                  <div
                    onClick={handleLogout}
                    className="w-full flex items-center justify-between cursor-pointer"
                  >
                    <span className="text-base font-medium flex-1">{menu.title}</span>
                  </div>
                ) : (
                  <Link
                  to={menu.submenu ? "#" : menu.path}
                  onClick={() => {
                    if (menu.submenu) {
                      toggleTab(menu.title);
                    }
                  }}
                  className="w-full flex items-center justify-between"
                  aria-expanded={openTab === menu.title}
                >
                  <span className="text-base font-medium flex-1">{menu.title}</span>
                  {menu.submenu && (
                    <BsChevronDown
                      className={`transition-transform duration-300 ${
                        openTab === menu.title ? "rotate-180" : ""
                      }`}
                    />
                  )}
                </Link>
                

                )}
              </li>
              {menu.submenu && openTab === menu.title && (
                <ul>
                  {menu.submenuItems.map((submenuItem, subIndex) => (
                    <li
                      key={subIndex}
                      className={`text-white-300 text-sm hover:bg-gray-700 hover:text-white rounded-md mt-3 ml-3 mr-3 ${getActiveClass(submenuItem.path)}`}
                    >
                      <Link
                        to={submenuItem.path}
                        onClick={handleLinkClick}
                        className="w-full flex items-center gap-x-4 p-2 px-8"
                      >
                        {submenuItem.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </React.Fragment>
          ))}
        </ul>
      </div>

      {isSidebarOpen && isMobile && (
        <div
          className="fixed top-0 left-0 w-full h-full bg-black opacity-50 z-40"
          onClick={toggleSidebar}
        ></div>
      )}
    </div>
  );
};

export default Sidebar;
