import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { StopCharging } from "../../api";

const ChargingDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { bookingId } = location.state || {};

  const [formData, setFormData] = useState({
    finalChargingLevel: "",
    finalKmRange: "",
  });

  const [otp, setOtp] = useState(["", "", "", ""]);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleOtpChange = (e, index) => {
    const value = e.target.value;
    if (/^\d$/.test(value) || value === "") {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (value && index < 3) {
        document.getElementById(`otp-box-${index + 1}`).focus();
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!bookingId) {
      alert("Booking ID is missing!");
      return;
    }

    const endOtp = parseInt(otp.join(""));
    if (isNaN(endOtp) || otp.some((digit) => digit === "")) {
      alert("Please enter a valid 4-digit OTP.");
      return;
    }

    const { finalChargingLevel, finalKmRange } = formData;

    if (!finalChargingLevel || !finalKmRange) {
      alert("Please fill out all fields.");
      return;
    }

    const payload = {
      endOtp,
      finalPercentage: parseInt(finalChargingLevel),
      finalRangeKms: parseInt(finalKmRange),
    };

    try {
      setLoading(true);
      const response = await StopCharging(bookingId, payload);
      if (response) {
        alert("Charging has been completed!");
        navigate("/home"); 
      }
    } catch (error) {
      console.error("Error completing charging:", error.message);
      alert("Failed to complete charging. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-3xl font-bold mb-12">Charging Details</h1>
      <form className="space-y-6" onSubmit={handleSubmit}>
        <div>
          <label className="block text-lg mb-4">Enter Final Charging Level</label>
          <input
            type="number"
            name="finalChargingLevel"
            value={formData.finalChargingLevel}
            onChange={handleChange}
            className="w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400 transition duration-300"
            placeholder="Enter %"
            required
          />
        </div>

        <div>
          <label className="block text-lg mb-4">Enter Final km Range</label>
          <input
            type="number"
            name="finalKmRange"
            value={formData.finalKmRange}
            onChange={handleChange}
            className="w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400 transition duration-300"
            placeholder="Enter km"
            required
          />
        </div>

        <div className="flex items-center space-x-4 mt-6">
          <label className="block text-lg mb-2">Enter End OTP</label>
          <div className="flex space-x-2">
            {otp.map((digit, index) => (
              <input
                key={index}
                id={`otp-box-${index}`}
                type="text"
                value={digit}
                onChange={(e) => handleOtpChange(e, index)}
                className="w-12 h-12 text-2xl text-center border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
                maxLength={1}
              />
            ))}
          </div>
        </div>

        <div className="flex space-x-4">
          <button
            type="submit"
            className={`bg-red-700 text-white py-3 px-8 rounded-lg hover:bg-red-800 transition duration-300 w-full sm:w-auto ${loading ? "opacity-50" : ""}`}
            disabled={loading}
          >
            {loading ? "Processing..." : "Complete Charging"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ChargingDetails;
