const endpoints = {

  verifyMigration: (requestId) =>
    `/store/pg/migration/${requestId}/verify`,

  cleanupMigration: (requestId) =>
    `/store/pg/migration/${requestId}/cleanup`,

  getAllOperators: '/internaluser/operator/list',

  getOperatorDetails: (operatorId) =>
    `/internaluser/operator/${operatorId}/details`,

  fetchLocationSuggestions1: (query) =>
    `/internaluser/google/place/autocomplete?prompt=${query}`,

  fetchHealthCheck: '/health',
  
  onDemandBookingActive:'/internaluser/ondemandbookings/active',

  onDemandBookingAll:'/internaluser/ondemandbookings/all',

  StartCharging: (bookingId)=>
    `/internaluser/ondemandbooking/${bookingId}/start-charging-session`,

    StopCharging: (bookingId)=>
    `/internaluser/ondemandbooking/${bookingId}/stop-charging-session`,

};

export default endpoints;

 