import { ROUTES } from "../routes";

export const SIDEBAR_TILES = [
  {
    title: "Model",
    submenu: true,
    submenuItems: [
      { title: "Add Model", path: `/${ROUTES.ADD_VEHICLE}` },
      { title: "Model List", path: `/${ROUTES.MODEL_LIST}` },
    ],
  },
  {
    title: "Store",
    submenu: true,
    submenuItems: [
      { title: "Store Details", path: `/${ROUTES.STORE_DETAILS}` },
      { title: "Store Search", path: `/${ROUTES.STORE_SEARCH}` },
      { title: "Charging Stations", path: `/${ROUTES.CHARGING_STATION}` },
      { title: "Add Store", path: `/${ROUTES.ADD_STORE}` },
    ],
  },
  { title: "Home", 
    spacing: true,
    path: `/${ROUTES.HOME}` },
  { title: "Create Business", path: `/${ROUTES.CREATE_BUSINESS}` },
  { title: "QR Resolve", path: `/${ROUTES.QR_RESOLVE}`  },
  { title: "Booking Details", path: `/${ROUTES.BOOKING_DETAILS}` },
  { title: "Operator List", path: `/${ROUTES.OPERATOR}`},
  { title: "Migration Details", path: `/${ROUTES.MIGRATION}` },
  { title: "Location", path: `/${ROUTES.PLACES}`},
  {
    title: "HealthCheck",
    path: `/${ROUTES.HEALTH_CHECK}`,
    visibleTo: "INTERNAL_ADMIN" 
  },
  { title: "On Demand", path: `/${ROUTES.ONDEMAND_CHARGING}`},
  {
    title: "Logout",
    spacing: true,
  }
];

export const SIDEBAR_BREAKPOINT = 1024;
