import React, { useState } from 'react';
import AddImage from "../../src/components/AddImage"; 

const ImageUpload = () => {
  // eslint-disable-next-line no-unused-vars
  const [uploadSuccess, setUploadSuccess] = useState(false);

  const handleUploadComplete = (imageId) => {
    // console.log(uploadSuccess)
    // console.log('Upload completed with image ID:', imageId);
    setUploadSuccess(true);
  };

  return (
    <div className="w-full max-w-md mx-auto p-4">
      <h2 className="text-xl font-semibold mb-4">Upload Charging Station Photo</h2>
      <div className="bg-white p-6 rounded-lg shadow-md">
        <AddImage
          onUploadComplete={handleUploadComplete}
        />
      </div>

     
    </div>
  );
};

export default ImageUpload;
