import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../Context";
import { fetchConnectorTypes ,AddChargingStation} from "../api";
import { ROUTES } from "../routes";

const AddOutlet = () => {
  const [stationCount, setStationCount] = useState(0);
  const [stations, setStations] = useState([]);
  const [connectorTypes, setConnectorTypes] = useState([]);
  const navigate = useNavigate();
  const { storeData, payload: contextPayload } = useAppContext();

  // console.log("Payload received:", contextPayload);
  // console.log("Store data received:", storeData);

  
  useEffect(() => {
    const getConnectorTypes = async () => {
      try {
        const response = await fetchConnectorTypes();
        if (response && response.connectorTypeDetails) {
          setConnectorTypes(response.connectorTypeDetails); 
        } else {
          setConnectorTypes([]); 
        }
      } catch (error) {
        console.error("Error fetching connector types:", error);
        setConnectorTypes([]);
      }
    };
  
    getConnectorTypes();
  }, []);
  

  const handleStationCountSubmit = () => {
    const initialStations = Array.from({ length: stationCount }, (_, index) => ({
      stationId: index + 1,
      evseRequests: [
        {
          evseId: 1,
          evseConnectorId: 1,
          type: connectorTypes[0]?.connectorType || "UNKNOWN",
          powerRatingKWH: 0,
          price: { rate: "0", unit: "KWH" },
        },
      ],
    }));
    setStations(initialStations);
  };

  const handleAddEVSE = (stationIndex) => {
    const updatedStations = [...stations];
    const station = updatedStations[stationIndex];

    const newEvseId = station.evseRequests.length + 1;
    station.evseRequests.push({
      evseId: newEvseId,
      evseConnectorId: newEvseId,
      type: connectorTypes[0]?.connectorType || "TYPE_2", 
      powerRatingKWH: 0,
      price: { rate: "0", unit: "KWH" },
    });

    setStations(updatedStations);
  };

  const handleChange = (stationIndex, evseIndex, field, value) => {
    const updatedStations = [...stations];
    const evse = updatedStations[stationIndex].evseRequests[evseIndex];

    if (field === "type") evse.type = value;
    else if (field === "powerRatingKWH") evse.powerRatingKWH = Number(value);
    else if (field === "price.rate") evse.price.rate = Number(value);

    setStations(updatedStations);
  };

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const handleSubmit = async () => {
    try {
      for (let i = 0; i < stations.length; i++) {
        const station = stations[i];
      
        const payload = {
          operatorId: contextPayload?.storeRequest?.operatorId || null,
          storeId: storeData?.storeId || null,
          name: contextPayload?.storeRequest?.name || null,
          operatorStationId: `Station${i + 1}`, // Assign a unique station ID
          latLng: {
            latitude: contextPayload?.storeRequest?.location?.latitude || null,
            longitude: contextPayload?.storeRequest?.location?.longitude || null,
          },
          evseRequests: [
            {
              evseId: station.evseRequests[0]?.evseId || null,
              requests: station.evseRequests.map((evse) => ({
                evseId: evse.evseId,
                evseConnectorId: evse.evseConnectorId,
                type: evse.type,
                powerRatingKWH: evse.powerRatingKWH,
                price: {
                  rate: parseFloat(evse.price.rate),
                  unit: evse.price.unit,
                },
              })),
            },
          ],
        };
      
        // Clean up null or undefined fields
        const cleanPayload = JSON.parse(JSON.stringify(payload));
      
        // console.log(`Sending payload for Station ${i + 1}:`, JSON.stringify(cleanPayload, null, 2));
      
        try {
          // Send the API request
          // eslint-disable-next-line no-unused-vars
          const response = await AddChargingStation(cleanPayload);
      
          // console.log(`API Response for Station ${i + 1}:`, response);
        } catch (error) {
          console.error(`Error sending payload for Station ${i + 1}:`, error.message);
        }
        if (i < stations.length - 1) {
          await sleep(500);
        }
      }
      
  
      navigate(`/${ROUTES.IMAGE}`);
    } catch (error) {
      if (error.response) {
        console.error("Error Response Data:", error.response.data);
        console.error("Error Response Status:", error.response.status);
      } else {
        console.error("Error Message:", error.message);
      }
    }
  };
  
  

  return (
    <div className="min-h-screen flex flex-col items-center p-4">
      <div className="bg-white shadow-lg rounded-lg p-6 w-full">
        <h1 className="text-2xl font-semibold text-gray-800 mb-6">Add Outlet</h1>

        {!stations.length ? (
          <div>
            <label className="block mb-2 text-gray-700">Enter Station Count</label>
            <input
              type="number"
              className="block w-full p-2 border rounded"
              value={stationCount}
              onChange={(e) => setStationCount(Number(e.target.value))}
            />
            <button
              className="bg-blue-500 text-white px-4 py-2 rounded mt-4"
              onClick={handleStationCountSubmit}
            >
              Generate Stations
            </button>
          </div>
        ) : (
          stations.map((station, stationIndex) => (
            <div key={station.stationId} className="mb-4">
              <h2 className="text-xl font-medium text-gray-700">
                Station {station.stationId}
              </h2>
              {station.evseRequests.map((evse, evseIndex) => (
                <div key={evse.evseId} className="mb-4">
                  <h3 className="text-lg font-medium text-gray-600">
                    EVSE {evse.evseId}
                  </h3>
                  <label className="block mb-1 text-gray-600">Connector Type</label>
                  <select
                    className="block w-full p-2 border rounded"
                    value={evse.type}
                    onChange={(e) =>
                      handleChange(stationIndex, evseIndex, "type", e.target.value)
                    }
                  >
                    {connectorTypes.map((type) => (
                      <option key={type.connectorType} value={type.connectorType}>
                        {type.connectorType}
                      </option>
                    ))}
                  </select>
                  <label className="block mt-2 text-gray-600">Power Rating (KWH)</label>
                  <input
                    type="number"
                    className="block w-full p-2 border rounded"
                    value={evse.powerRatingKWH}
                    onChange={(e) =>
                      handleChange(
                        stationIndex,
                        evseIndex,
                        "powerRatingKWH",
                        e.target.value
                      )
                    }
                  />
                  <label className="block mt-2 text-gray-600">Price Rate</label>
                  <input
                    type="number"
                    className="block w-full p-2 border rounded"
                    value={evse.price.rate}
                    onChange={(e) =>
                      handleChange(stationIndex, evseIndex, "price.rate", e.target.value)
                    }
                  />
                </div>
              ))}
              <button
                className="text-blue-500 underline mt-2"
                onClick={() => handleAddEVSE(stationIndex)}
              >
                Add more Connector to station {station.stationId}
              </button>
            </div>
          ))
        )}

        <button
          className="bg-blue-500 text-white px-4 py-2 rounded mt-6"
          onClick={handleSubmit}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default AddOutlet;
