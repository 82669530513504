import React, { useEffect, useState } from 'react';
import { getAllOperators ,createNewStore,  fetchEnums,fetchLocationSuggestions} from '../api';
import { useNavigate } from "react-router-dom";
import { useAppContext } from '../Context';
import { ROUTES } from "../routes";

const AddStore = () => {
  const [operators, setOperators] = useState([]);
  const [filteredOperators, setFilteredOperators] = useState([]);
  const [selectedOperator, setSelectedOperator] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [location, setLocation] = useState(null);
  const [placeResults, setPlaceResults] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [nameQuery, setNameQuery] = useState('');
  const [description,setDescription]=useState('')
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [locationType, setLocationType] = useState(''); 
  const [locationTag, setLocationTag] = useState('')
  const [locationTypes, setLocationTypes] = useState([]); 
  const [locationTags, setLocationTags] = useState([]);
  const [size, setSize] = useState('');
  const [sizes, setSizes] = useState([]);
  const [restrictionLevel,setRestrictionLevel]=useState("");
  const [restrictionLevels,setRestrictionLevels]=useState([]);
  const [parking, setParking] = useState('');
  const [parkings, setParkings] = useState([]);
  const [available247, setAvailable247] = useState(true);
  const [amenityTypes, setAmenityTypes] = useState([]); 
  const [amenitiesOptions,setAmenitiesOptions] =useState([])
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [isRatingValid, setIsRatingValid] = useState(true);
  const [rating, setRating] = useState('');
  const navigate = useNavigate();
  const [enums, setEnums] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { setStoreData,setPayload } = useAppContext();

  useEffect(() => {
    const loadEnums = async () => {
      try {
        const data = await fetchEnums();
        // console.log("Fetched Enums:", data);
        setEnums(data);
        setLocationTypes(data.locationTypes || []);
        setLocationTags(data.locationTags || []);
        setSizes(data.sizes || []);
        setRestrictionLevels(data.restrictionLevels);
        setParkings(data.parkingLevels)
        setAmenitiesOptions(data.amenityTypes || []);
      } catch (err) {
        console.error("Failed to fetch enums:", err);
        setError(err.message || "Failed to fetch enums.");
      } finally {
        setLoading(false);
      }
    };
    loadEnums();
  }, []);

  useEffect(() => {
    const fetchCurrentLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setLocation({ latitude, longitude });
            // console.log(latitude, longitude);
          },
          (error) => {
            console.error("Error fetching location:", error);
            alert("Unable to fetch current location");
          }
        );
      } else {
        alert("Geolocation is not supported by this browser.");
      }
    };
    fetchCurrentLocation();
  }, []);

  
  
  const handleRatingChange = (e) => {
    const value = e.target.value;
    const ratingValue = parseInt(value, 10);
    setRating(value);
    setIsRatingValid(ratingValue >= 1 && ratingValue <= 10);
  };

  const handleAmenityChange = (amenity) => {
    setAmenityTypes((prevAmenities) =>
      prevAmenities.includes(amenity)
        ? prevAmenities.filter((item) => item !== amenity)
        : [...prevAmenities, amenity]
    );
  };

  useEffect(() => {
    const fetchOperators = async () => {
      try {
        const data = await getAllOperators();
        setOperators(data);
        setFilteredOperators(data); 
      } catch (error) {
        console.error('Failed to fetch operators:', error);
      }
    };
    fetchOperators();
  }, []);
  
  const handlePhoneChange = (e) => {
    const value = e.target.value;
    const phoneRegex = /^[0-9]{10}$/; 
    setPhoneNumber(value);
    setIsPhoneValid(phoneRegex.test(value));
  };

  useEffect(() => {
    const fetchPlaces = async () => {
      if (!searchQuery) {
        // console.log("Please enter a location prompt.");
        return;
      }

      try {
        // eslint-disable-next-line no-unused-vars
        const response = await fetchLocationSuggestions(searchQuery, setPlaceResults);
        // console.log(response.predictions); 
      } catch (error) {
        console.error("Error fetching places:", error);
      }
    };
  fetchPlaces(); 
  }, [searchQuery]);
 
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);

    const filtered = operators.filter((operator) =>
      operator.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredOperators(filtered);
    setIsDropdownOpen(value.length > 0);
  };

  const handleOperatorSelect = (operator) => {
    setSelectedOperator(operator);
    setSearchValue(operator.name);
    setIsDropdownOpen(false);
  };

  const handleToggle = () => {
    setAvailable247(!available247);
  };
  
  const handlePlaceSelect = (place) => {
    setSelectedPlace(place);
    setSearchQuery(place.description);
    setPlaceResults([]); 
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedOperator || !selectedPlace || !isPhoneValid || !isRatingValid) {
      alert("Please fill all required fields correctly.");
      return;
    }
    // console.log("Address Line 1",selectedPlace?.structuredFormatting?.secondaryText)
    const city = selectedPlace.terms[selectedPlace.terms.length - 3]?.value;
    const state = selectedPlace.terms[selectedPlace.terms.length - 2]?.value;
    // console.log("placeid",selectedPlace?.placeId)
    const payload = {
      storeRequest:{
        operatorId: selectedOperator?.operatorId,
        googlePlaceDetails: {
          placeId: selectedPlace?.placeId,
        },
        name: nameQuery,
        description: description,
        location: {
          latitude: location.latitude , 
          longitude: location.longitude, 
          city: city.toUpperCase(), 
          state: state.toUpperCase(),
          addressLine1: selectedPlace?.structuredFormatting?.secondaryText,
        },
        locationType:locationType.toUpperCase(),
        locationTags:[locationTag.toUpperCase()], 
        size: size.toUpperCase(),
        restrictionLevel:restrictionLevel.toUpperCase(),
        parkingLevel: parking.toUpperCase(),
        timings: {
          available247: available247,
        },
        contactInfo: {
            primarySupportNumber: {
                countryCode: 91,
                phoneNumber: phoneNumber
            }
        },
        amenities: {
          amenityTypes: amenityTypes, 
        },
      },
      averageStoreRating: parseInt(rating, 10),
    };
    try {
      // console.log("Payload being sent:", JSON.stringify(payload, null, 2));
      const response = await createNewStore(payload);
      // console.log("Store created successfully:", response);
      setPayload(payload)
      setStoreData(response); 
      navigate(`/${ROUTES.OUTLET}`);
    } catch (error) {
      console.error("Error adding store:", error);
    }
  };
  if (loading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Error: {error}</div>;
  }
  if(enums){
    // console.log(enums)
  }
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 p-4 md:p-8 lg:p-12">
    <div className="bg-white shadow-lg rounded-lg p-6 w-full max-w-lg md:max-w-2xl lg:max-w-3xl">
        <h1 className="text-2xl font-semibold text-gray-800 mb-6 text-center md:text-left">
          Add A New Store
        </h1>
  
        {/* Select Operator */}
        <div className="relative w-full mb-6">
          <label className="block text-gray-700 mb-2">Select Operator</label>
          <div className="flex items-center border rounded px-4 py-2">
            <input
              type="text"
              value={searchValue}
              onChange={handleSearchChange}
              onClick={(e) => e.stopPropagation()}
              placeholder="Search for an operator"
              className="flex-grow focus:outline-none"
            />
          </div>
  
          {isDropdownOpen && (
            <ul className="absolute left-0 right-0 bg-white border border-gray-300 mt-1 max-h-60 overflow-y-auto z-10">
              {filteredOperators.length > 0 ? (
                filteredOperators.map((operator) => (
                  <li
                    key={operator.id}
                    onClick={() => handleOperatorSelect(operator)}
                    className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                  >
                    {operator.name}
                  </li>
                ))
              ) : (
                <li className="px-4 py-2 text-gray-500">No results found</li>
              )}
            </ul>
          )}
        </div>
  
        {/* Search Location */}
        <div className="w-full mb-6">
          <label className="block text-gray-700 mb-2">Search Location</label>
          <div className="flex items-center border rounded px-4 py-2">
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Enter location prompt"
              className="flex-grow focus:outline-none"
            />
          </div>
  
          {placeResults.length > 0 && (
            <ul className="absolute left-0 right-0 bg-white border border-gray-300 mt-1 max-h-60 overflow-y-auto z-10">
              {placeResults.map((place) => (
                <li
                  key={place.place_id}
                  onClick={() => handlePlaceSelect(place)}
                  className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                >
                  {place.description}
                </li>
              ))}
            </ul>
          )}
        </div>
  
        {/* Store Name */}
        <div className="w-full mb-6">
          <label className="block text-gray-700 mb-2">Name of the Store</label>
          <div className="flex items-center border rounded px-4 py-2">
            <input
              type="text"
              value={nameQuery}
              onChange={(e) => setNameQuery(e.target.value)}
              placeholder="Enter The Name of the Store"
              className="flex-grow focus:outline-none"
            />
          </div>
        </div>

         {/* Description Of The Store */}
         <div className="w-full mb-6">
          <label className="block text-gray-700 mb-2">Description of The Store</label>
          <div className="flex items-center border rounded px-4 py-2">
            <input
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Enter The Description of the Store"
              className="flex-grow focus:outline-none"
            />
          </div>
        </div>
          
        {/* Location Types */}
    <div className="w-full mb-6">
      <label className="block text-gray-700 mb-2">Location Type</label>
      <div className="flex items-center border rounded px-4 py-2">
        <select
          value={locationType}
          onChange={(e) => setLocationType(e.target.value)} // Updates the selected location type
          className="flex-grow focus:outline-none bg-white"
        >
          <option value="" disabled>Select Location Type</option>
          {locationTypes.map((type) => (
            <option key={type} value={type}>
              {type.charAt(0).toUpperCase() + type.slice(1).toLowerCase()}
            </option>
          ))}
        </select>
      </div>
    </div>

    {/* Location Tags */}
    <div className="w-full mb-6">
      <label className="block text-gray-700 mb-2">Location Tags</label>
      <div className="flex items-center border rounded px-4 py-2">
        <select
          value={locationTag}
          onChange={(e) => setLocationTag(e.target.value)} // Updates the selected location restrictionLevels
          className="flex-grow focus:outline-none bg-white"
        >
          <option value="" disabled>Select Location Tag</option>
          {locationTags.map((restrictionLevels) => (
            <option key={restrictionLevels} value={restrictionLevels}>
              {restrictionLevels.charAt(0).toUpperCase() + restrictionLevels.slice(1).toLowerCase()}
            </option>
          ))}
        </select>
      </div>
    </div>
  
        {/* Store Size */}
        <div className="w-full mb-6">
      <label className="block text-gray-700 mb-2">Size of the Store</label>
      <div className="flex items-center border rounded px-4 py-2">
        <select
          value={size}
          onChange={(e) => setSize(e.target.value)}
          className="flex-grow focus:outline-none bg-white"
        >
          <option value="" disabled>
            Select a size
          </option>
          {sizes.map((sizeOption) => (
            <option key={sizeOption} value={sizeOption}>
              {sizeOption.charAt(0).toUpperCase() + sizeOption.slice(1).toLowerCase()}
            </option>
          ))}
        </select>
      </div>
    </div>

     {/* Restriction Level*/}
     <div className="w-full mb-6">
      <label className="block text-gray-700 mb-2">Restriction Level</label>
      <div className="flex items-center border rounded px-4 py-2">
        <select
          value={restrictionLevel}
          onChange={(e) => setRestrictionLevel(e.target.value)} 
          className="flex-grow focus:outline-none bg-white"
        >
          <option value="" disabled>Select Restriction Level</option>
          {restrictionLevels.map((restrictionLevels) => (
            <option key={restrictionLevels} value={restrictionLevels}>
              {restrictionLevels.charAt(0).toUpperCase() + restrictionLevels.slice(1).toLowerCase()}
            </option>
          ))}
        </select>
      </div>
    </div>
  
        {/* Parking Space */}
        <div className="w-full mb-6">
    <label className="block text-gray-700 mb-2">Parking</label>
    <div className="flex items-center border rounded px-4 py-2">
      <select
        value={parking}
        onChange={(e) => setParking(e.target.value)} // Updates the selected parking state
        className="flex-grow focus:outline-none bg-white"
      >
        <option value="" disabled>
          Select Parking Space
        </option>
        {parkings.map((parkingOption) => (
          <option key={parkingOption} value={parkingOption}>
            {parkingOption.charAt(0).toUpperCase() + parkingOption.slice(1).toLowerCase()}
          </option>
        ))}
      </select>
    </div>
  </div>
  
        {/* Available 24/7 */}
        <div className="w-full mb-6">
          <label className="block text-gray-700 mb-2">Available 24/7</label>
          <div className="flex items-center">
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                checked={available247}
                onChange={handleToggle}
                className="sr-only"
              />
              <div className="w-11 h-6 bg-gray-300 rounded-full peer focus:outline-none peer-checked:bg-green-500">
                <div
                  className={`dot absolute left-1 top-1 w-4 h-4 bg-white rounded-full transition-transform ${
                    available247 ? 'transform translate-x-5' : ''
                  }`}
                />
              </div>
            </label>
            <span className="ml-3 text-gray-700">{available247 ? 'Yes' : 'No'}</span>
          </div>
        </div>
  
        {/* Phone Number */}
        <div className="w-full mb-6">
          <label className="block text-gray-700 mb-2">Phone Number of the Store Owner</label>
          <input
            type="text"
            value={phoneNumber}
            onChange={handlePhoneChange}
            placeholder="Enter phone number"
            className={`border rounded px-4 py-2 w-full focus:outline-none ${
              isPhoneValid ? 'border-gray-300' : 'border-red-500'
            }`}
          />
          {!isPhoneValid && (
            <p className="text-red-500 text-sm mt-1">Please enter a valid 10-digit phone number.</p>
          )}
        </div>
  
        {/* Amenities */}
        <div className="w-full mb-6">
  <label className="block text-gray-700 mb-2">Amenities</label>
  <div className="flex flex-wrap">
    {amenitiesOptions.map((amenity) => (
      <label key={amenity} className="flex items-center mr-4 mb-2">
        <input
          type="checkbox"
          checked={amenityTypes.includes(amenity)}
          onChange={() => handleAmenityChange(amenity)}
          className="mr-2"
        />
        <span className="text-gray-700">{amenity}</span>
      </label>
    )) || <span>Loading amenities...</span>}
  </div>
</div>

  
        {/* Rating */}
        <div className="w-full mb-6">
          <label className="block text-gray-700 mb-2">Rating (1 to 10)</label>
          <input
            type="number"
            value={rating}
            onChange={handleRatingChange}
            placeholder="Enter rating"
            className={`border rounded px-4 py-2 w-full focus:outline-none ${
              isRatingValid ? 'border-gray-300' : 'border-red-500'
            }`}
          />
          {!isRatingValid && (
            <p className="text-red-500 text-sm mt-1">Rating must be between 1 and 10.</p>
          )}
        </div>
  
        {/* Submit Button */}
        <button
          onClick={handleSubmit}
          className="mt-4 px-6 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 w-full md:w-auto"
        >
          Submit
        </button>

      </div>
    </div>
  );
  
};

export default AddStore;
